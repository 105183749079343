import * as React from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";

import { assessmentService } from "../services";
import { setTimeToCookie } from "../utils/helper";
import { assessmentSlice } from "../redux/assessment/slice";
import { linkInfoSlice } from "../redux/linkInfo/slice";
import * as flowControlActions from "../redux/flowControl/action";

import PageError from "../pages/Error/PageError";
import PageLoader from "../Components/Loader/PageLoader";
import { Navigate, useParams } from "react-router-dom";
import { PAGE_PATH } from "../router/path";
import Cookies from "js-cookie";
import { extractInviteData } from "../utils/extractInviteData";

const InviteGuard = ({ children }) => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const assessment = useSelector((state) => state.assessment);

  function handleInviteError(err) {
    Cookies.remove("authToken", { path: PAGE_PATH.ASSESS_INVITE(id) });
    const aid = localStorage.getItem(`iid_${id}`);
    localStorage.removeItem(`iid_${id}`);
    localStorage.removeItem(`aid_${aid}`);
    dispatch(
      assessmentSlice.actions.setAssessment({
        data: null,
        error: err.message,
      })
    );
  }

  React.useEffect(() => {
    async function fetchInvite() {
      try {
        const inviteData = await assessmentService.getInvite();
        const flowControl = inviteData.summary.flowControl;
        // console.log("flowControl: ", flowControl);
        dispatch(linkInfoSlice.actions.setLinkInfo(inviteData));
        await dispatch(
          assessmentSlice.actions.setAssessment({
            data: extractInviteData(inviteData),
            error: null,
          })
        );
        if (flowControl?.assessment?.__initialized) {
          await dispatch(flowControlActions.updateAV2(flowControl.assessment));
          await dispatch(flowControlActions.updateTV2(flowControl.currentTest));
        } else {
          await dispatch(flowControlActions.initializeAV2(inviteData));
        }

        setTimeToCookie(inviteData);
      } catch (err) {
        console.error(err);
        handleInviteError(err);
      }
    }

    fetchInvite();
  }, []);

  if (!assessment.__initialized) return <PageLoader />;

  if (!assessment.__data) {
    // toast.error("Invalid Invite Url");
    // window.location.href = window.location.origin;
    return <PageError message={"Something went wrong"}/>;
  }

  return children;
};

export default InviteGuard;
