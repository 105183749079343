import { combineReducers } from "redux";
import { linkInfoSlice } from './linkInfo/slice'
import { assessmentSlice} from './assessment/slice'
import { flowControlSlice } from "./flowControl/slice";

export const rootReducer = combineReducers({
  linkInfo: linkInfoSlice.reducer,
  assessment: assessmentSlice.reducer,
  flowControl: flowControlSlice.reducer
});
