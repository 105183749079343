export const STEPS = {
    UserConfirm: {
        slug: 'UserConfirm',
        order: 1
    },
    Introduction: {
        slug: 'Introduction',
        order: 2
    },
    CameraMicSetup: {
        slug: 'CameraMicSetup',
        order: 3
    },
    GuidelineAssessment: {
        slug: 'GuidelineAssessment',
        order: 4
    },
    TestWindow: {
        slug: 'TestWindow',
        order: 5
    },
    Submitted: {
        slug: 'Submitted',
        order: 6
    },
    Terminated: {
        slug: 'Terminated',
        order: 7
    },
}

export const getTestProgressDTO = ({ isPublicLink, isProctoringEnabled }) => {
    return {
        [STEPS.UserConfirm.slug]: {
            skipped: false,
            completed: isPublicLink,
        },
        [STEPS.Introduction.slug]: {
            skipped: false,
            completed: false,
        },
        [STEPS.CameraMicSetup.slug]: {
            skipped: !isProctoringEnabled,
            completed: false
        },
        [STEPS.GuidelineAssessment.slug]: {
            skipped: false,
            completed: false
        },
        [STEPS.TestWindow.slug]: {
            skipped: false,
            completed: false
        },
        [STEPS.Submitted.slug]: {
            skipped: false,
            completed: false
        },
        [STEPS.Terminated.slug]: {
            skipped: false,
            completed: false
        },
    }

}
